@import "variables";

.Contacts {
  background-color: $color_dark_dark;
  display: flex;
  height: 100vh;
  @include separators;
}

.ContactsMap {
  height: 50vh;
  align-content: center;

  @media (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }
}

.Contacts__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contacts__Header {
  //padding: 10vw 0 2vw;
  text-align: center;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .Contacts__Header {
    border-top: none;
    height: 6vw;
    margin: 1vw;
  }
}

.Contacts__TypeContainer {
  position: relative;
  //width: 42vw;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .Contacts__TypeContainer {

  }
}

.Contacts__Type {
  color: $color_light;

  @media (max-width: 1024px) and (orientation: portrait) {
    font-size: 20px !important;
  }
}

.Contacts__List {
  align-content: center;
  color: $color_light;
  display: flex;
  //flex-wrap: wrap;
  //margin: auto;
  font-family: Graphik, sans-serif;
  font-size: 1vw;
  font-weight: 500;
  min-height: 25vw;
  opacity: 1;
  padding: 4vw 0 4vw;
  position: relative;
  transition: opacity .9s $transition-timing-function 0s;
  width: 70%;
  justify-content: space-evenly;

  @media (max-width: 1024px) and (orientation: portrait) {
    font-size: 12px;
  }
}

.Contacts__ListItem {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  //width: auto;
  text-align: center;
}

.Contacts__ListItemTitle {
  color: $color_light;
  line-height: 4vw;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.Contacts__ListItemIcon {
  display: flex;
  margin: 1vw auto 2vw;

  background-color: $color_evidence;
  border-radius: 5vw;
  height: 10vw;
  width: 10vw;
  transition: all 0.6s $transition-timing-function;

  @media (max-width: 1024px) and (orientation: portrait) {
    border-radius: 40px;
    height: 80px;
    width: 80px;
  }

  &:hover {
    background-color: $color_light;

    svg > path {
      fill: $color_dark;
    }
  }
}

.Contacts__ListItemIcon > svg {
  margin: auto;
  width: 4vw;
  height: 4vw;

  @media (max-width: 1024px) and (orientation: portrait) {
    height: 40px;
    width: 40px;
  }
}

.Contacts__ListItemIcon > svg > path {
  fill: $color_light;
  pointer-events: all;
}

.Contacts__ListItemContent {
  //flex-wrap: wrap;
  //cursor: pointer;
}
