@import "variables";

.Comparison {
  //position: relative;
  //padding-bottom: 60%; /* to maintain aspect ratio (responsive!) */
  height: 100vh;
  //@include separators;
}

.Comparison__Image {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100vw;

  //width: 100%;
  //height: 100%;
  object-fit: cover;
  //overflow: hidden;
}

.Comparison__AfterImage {
  position: absolute;
  overflow: hidden;
  top: 0;
  transform: translate(100%, 0px);
}

.Comparison__AfterImage .Comparison__ImageBg {
  transform: translate(-100%, 0px);
}

.Comparison__Image .Comparison__ImageBg {
  background: no-repeat center center fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
