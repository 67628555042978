@import "../variables";

.Button {
  border: solid thin $color_light;
  border-radius: 20px;
  overflow: hidden;
  max-height: 40px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  color: $color_evidence;
  display: flex;
  flex-direction: column;
  letter-spacing: .05em;

  &--dark {
    border-color: $color_dark;
    background-color: $color_dark;
  }

  @include media-breakpoint-up(md) {
    font-size: 2vw;
    height: 3vw;
    border-radius: 3vw;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.75rem;
  }

  &:hover, &:active {
    color: $color_evidence;
  }

  * {
    display: none;
  }

  &:before, &:after {
    content: attr(data-label);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    height: 100%;
    text-transform: uppercase;
    transition: all .6s $transition-timing-function;
  }

  &:before {
    opacity: 1;
  }

  &:after {
    opacity: 0;
  }

  &:hover {
    &:before, &:after {
      transform: translateY(-100%);
    }

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}
