@import "variables";

.TopSpacer {
  background-color: $color_light;
  display: block;
  height: 8vw;
  margin: 0 2vw;
  width: 100%;

  &.isBottomLine {
    border-bottom: .07vw solid $color_dark_dark_opacity_15;
  }
}
