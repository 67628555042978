@import 'variables';

.SectionBoxButton {
  background-color: $color_dark;
  box-sizing: border-box;
  height: 20vw;
  width: 100%;

  @include separators;
}

.SectionBoxButton__Container {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
}

.SectionBoxButton__Text {
  color: $color_light;
  display: flex;
  justify-content: center;
  padding-right: 5vw;
  width: 60vw
}

.SectionBoxButton__InputContainer {
  display: flex;
  flex-wrap: wrap;
  height: 3vw;
  justify-content: flex-start;
  padding-left: 5vw;
  width: 40vw
}



.SectionBoxButton__BadgeButton {
  background:none;
  border:solid thin $color_border_light;
  color: $color_evidence;
}

