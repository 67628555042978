@import 'variables';

// FILTER SLIDING VIEW
/*.FilterProducts {
  background-color: $color_dark_dark_opacity_15;
  cursor: pointer;
  height: 100vh;
  right: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .6s cubic-bezier(.86, 0, .12, 1) 0s;
  width: 0;
  z-index: 999
}


.FilterProducts.isOpen {
  opacity: 1;
  pointer-events: all;
  //transition-delay: 0.5s;
  width: 32.2vw;
}

.FilterProducts.isOpen .FilterProducts__Container {
  transform: none;
  transition-delay: 0.1s;
}

.FilterProducts__Container {
  background-color: $color_light;
  cursor: default;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition-delay: 0s;
  transition: transform 1.2s cubic-bezier(.86, 0, .12, 1);
  width: calc(32.2vw - 1px);
}*/

.FilterProducts__List {
  padding-top: 2vw;
  width: 100%;
}

.FilterProducts__Link.isSelected {
  opacity: 1
}

.FilterProducts__Link.anotherLinkHovered:not(.isCurrent) {
  opacity: .3
}

.FilterProducts__Link > a {
  color: inherit;
  text-decoration: none;
}

.FilterProducts__Link > a:after {
  background-color: $color_dark_dark_opacity_15;
  bottom: 0;
  content: "";
  height: .05vw;

  left: 13vw;
  right: 13vw;
  position: absolute;
}

.FilterProducts__ListTitle {
  margin-top: 2vw;
  margin-bottom: 2vw;
  position: relative;
  text-align: center;
  width: 100%;
}

.FilterProducts__ListTitle:after {
  background-color: $color_dark_dark_opacity_15;
  bottom: 0;
  content: "";
  height: .05vw;
  top: 3vw;
  left: 13vw;
  right: 13vw;
  position: absolute;
}

.FilterProducts__Link {
  color: $color_dark_dark;
  height: 3vw;
  text-align: center;
  width: 100%;

  /*&:hover {
    transition: opacity .6s $transition-timing-function 0s;
    opacity: .5
  }*/
}

.FilterProducts__Link > a {
  transition: opacity .6s $transition-timing-function 0s
}

.FilterProducts__Link > a:hover {
  display: flex;
  opacity: .5
}

.FilterProducts__Link input:checked + label {
  position: relative;

  &:after {
    background-color: $color_dark_dark_opacity_15;
    bottom: 0;
    content: "";
    height: 0.05vw;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.FilterProducts__InputFake {
  display: none;
}

/*.FilterProducts__Close {
  background-color: $color_dark;
  border-radius: 1.5vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 3vw;
  justify-content: center;
  left: 2vw;
  position: relative;
  top: 1vw;
  width: 3vw;
  //z-index:1;
}

.FilterProducts__Close::before,
.FilterProducts__Close::after {
  position: absolute;
  content: '';
  left: calc(50% - .4vw);
  width: .8vw;
  height: .07vw;
  background-color: $color_light;
}

.FilterProducts__Close::before {
  transform: rotate(45deg);
}

.FilterProducts__Close::after {
  transform: rotate(-45deg);
}

.FilterProducts__Close:hover:before {
  transform: none; //rotate(0deg);
}

.FilterProducts__Close:hover:after {
  transform: none; //rotate(90deg);
}*/
