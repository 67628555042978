@import 'variables';

.Email {
  background-color: $color_dark;
  box-sizing: border-box;
  height: 20vw;
  width: 100%;

  &__Container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    //margin-left: 5vw;
    width: 95vw;
  }

  &__Text {
    color: $color_light;
    font-size: 3vw;
    font-weight: 300;
    margin-left: 12.5vw;
    width: 50vw;
  }

  &__Input {
    -webkit-appearance: none;
    border: .07vw solid hsla(46, 24%, 89%, .2);
    border-radius: 3vw;
    box-sizing: border-box;
    caret-color: $color_light;
    color: $color_light;
    font-family: Graphik, sans-serif;
    font-size: .7vw;
    font-weight: 500;
    height: 4vw;
    letter-spacing: .06em;
    line-height: 2.2;
    margin-right: 20vw;
    padding: 1vw 2vw;
    text-transform: uppercase;
    width: 100%;

    &:-ms-input-placeholder {
      color: $color_light;
      -ms-transition: opacity .6s $transition-timing-function;
      transition: opacity .6s $transition-timing-function;
    }

    &::placeholder {
      color: $color_light;
      transition: opacity .6s $transition-timing-function;
    }

    &:focus:-ms-input-placeholder {
      opacity: 0;
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &Container {
      display: flex;
      flex-wrap: wrap;
      height: 4vw;
      justify-content: flex-end;
      width: 40vw;
    }
  }

  &__Submit {
    align-items: center;
    border: .07vw solid hsla(46, 24%, 89%, .2);
    border-radius: 4vw;
    cursor: pointer;
    display: flex;
    height: 4vw;
    justify-content: center;
    transition: border .6s c $transition-timing-function;
    width: 4vw;

    &:hover {
      border-color: $color_light;
    }

    & > svg {
      stroke: $color_light;
      width: 1vw;
    }
  }

  &__Messages {
    margin-top: .7vw;
    text-align: right;
    width: 100%;

    &--Success {
      color: $color_success;
    }

    &--Error {
      color: $color_error;
    }

    &--Loading {
      color: $color_light;
    }
  }
}


