@import "variables";

.enterByLetters {
  opacity: 0;
  transition: opacity 1.5s $transition-timing-function;

  & .word {
    transform: scale(2);
    transition: transform 1.5s $transition-timing-function;
  }

  &.onScreen {
    opacity: 1;

    & .word {
      transform: none;
    }
  }
}
