@import "variables";

.Gallery {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;

  &__Container {
    margin-top: 4vw;
    height: 100vh;
    width: 100%
  }

  &__List {
    background-color: $color_light;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 50vw;
    
    &Item {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100vh;
      justify-content: center;
      margin-bottom: 5vw;
      overflow: hidden;
      position: relative;
      width: 100%
    }
  }

  &__FullBg {
    height: 100vh;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    transform: scale(1);
    transition: transform .6s $transition-timing-function;
    width: 100%;
    z-index: 0
  }
}
