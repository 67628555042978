@import '../variables';

.Teaser {
  @include separators;

  background-color: $color_dark;
  color: $color_light;
  min-height: 20vw;
  padding: 3rem 0;

  display:flex;
  align-items: center;

  @for $i from 1 to 6 {
    h#{$i}, .h#{$i} {
      margin: 0;
      padding: 0 0 1.5rem;
      line-height: 1;
    }
  }

  &--variation-light {
    background-color: transparent;
    color: $color_dark_dark;

    .Button {
      border-color: $color_dark;
    }
  }

  &--variation-dark {
    background-color: $color_dark_dark;
  }

  &--orientation-rtl .row {
    flex-direction: row-reverse;
  }

  h1, .h1 {
    font-size: 2.875rem;
    line-height: .8;

    @include media-breakpoint-up(md) {
      font-size: 6vw;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 5.25rem;
    }
  }

  &--dimension-wide {
    height: 100vh;

    h1, .h1 {
      font-size: 3.8125rem;
      line-height: 1;

      @include media-breakpoint-up(md) {
        font-size: 8vw;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 7rem;
      }
    }
  }

  h2, .h2 {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 2vw;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
}

.Teaser__Margin {
  padding-top: 4vw
}
