@import "variables";

.Partners {
  background-color: $color_light;
  box-sizing: border-box;
  //height: 100vh;
  //margin-top: 8vw;
  position: relative;
  width: 100%;

  border-bottom: .07vw solid $color_dark_dark_opacity_15;


}

.Partners__Header {
  align-items: center;
  //border-top: .07vw solid $color_dark_dark_opacity_15;
  box-sizing: border-box;
  display: flex;
  //flex-wrap: wrap;
  height: 12vw;
  //justify-content: space-between;
  margin: 2vw 2vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 96%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Partners__Header {
    border-top: none;
    height: 3vw;
    margin: 1vw;
  }
}

.Partners__TypeContainer {
  position: relative;
  width: auto;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Partners__TypeContainer {

  }
}

.Partners__Type {
  color: $color_dark_dark;
  left: 0;
  top: 0;
}

.Partners__List {
  display: flex;
  flex-wrap: wrap;
  min-height: 25vw;
  opacity: 1;
  //padding: 4vw 0 2vw;
  transition: opacity .9s $transition-timing-function 0s;
  width: 100%;
}

.Partners__ListItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 33vw;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 22vw;
  background-color: $color_dark_opacity;
  margin-left: 2.3vw;
  margin-bottom: 2vw;
}

.Partners__ListItemBg {
  opacity: 50%;
  filter: grayscale(1);
  height: 100%;
  -o-object-fit: fill;
  object-fit: cover;
  position: absolute;
  transform: scale(1);
  transition: transform .6s $transition-timing-function;
  width: 100%;
  z-index: 0;
}

.Partners__ListItem:hover .Partners__ListItemBg {
  filter: grayscale(0);
  opacity: 100%;
  transform: scale(1.05);
  transition: all 0.6s $transition-timing-function;
}

.Partners__ListItemLogo {
  height: 4vw;
  position: relative;
  z-index: 1;
}

.Partners__ListItemTitle {
  font-size: 2vw;
  color: $color_light;
  position: relative;
  z-index: 1;
}
