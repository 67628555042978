@import 'variables';

// DETAIL SLIDING VIEW
/*.ProductDetail {
  background-color: $color_dark_dark_opacity_15;
  cursor: pointer;
  height: 150vh;
  left: 40vw;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 999
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail {
    width: 100%
  }
}

.ProductDetail.isOpen {
  opacity: 1;
  pointer-events: all;
  transition: opacity .6s cubic-bezier(.86, 0, .12, 1) 0s;
  width: 59.2vw;
}

.ProductDetail__FieldsContainer {
  background-color:  $color_light;
  cursor: default;
  //height: 200vh;
  position: absolute;
  //right: 0;
  //top: 0;
  //transform: translate3d(100%, 0, 0);
  transition: transform 1.2s cubic-bezier(.86, 0, .12, 1);
  width: 99.9%;
}


@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Container {
    width: 100%
  }
}

.ProductDetail.isOpen .ProductDetail__Container {
  transform: none
}*/

.ProductDetail__Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ProductDetail__FieldsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.2vw;
}

.ProductDetail__BrandLogo {
  height: 100px;
  left: auto;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden;
  //position: absolute;
  right: 0;
  width: 200px;
}

.ProductDetail__Image {
  height: auto;
  //margin-bottom: 1vw;
  padding: 1vw 2vw 0 1vw;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  width: 50vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Image {
    width: 100%;
    margin-bottom: 1vw;
  }
}

.ProductDetail__Infos {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Infos {
    align-items: flex-start;
    padding: 4vw 5vw;
    width: 100%;
  }
}

.ProductDetail__Name {
  padding-top: 5vw;
  text-align: center;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Name {
    display: none;
  }
}

.ProductDetail__Brand {
  margin-bottom: 1vw;
  text-align: center;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Brand {
    display: none;
  }
}

.ProductDetail__CompanyFields {
  border-bottom: thin solid $color_dark_dark_opacity_15;
  border-top: thin solid $color_dark_dark_opacity_15;
  height: auto;
  overflow: hidden;
  padding: 2vw 2vw;
  position: relative;
  transition: height .8s cubic-bezier(.86, 0, .12, 1);
  width: 40%;
}

.ProductDetail__Fields {
  border-bottom: thin solid $color_dark_dark_opacity_15;
  border-top: thin solid $color_dark_dark_opacity_15;
  height: auto;
  //margin-bottom: 7vw;
  overflow: hidden;
  padding: 2vw 3vw;
  position: relative;
  transition: height .8s cubic-bezier(.86, 0, .12, 1);
  width: 60%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Fields {
    height: 58vw;
    margin-bottom: 0;
    order: 3;
    padding: 7.5vw 0 15vw;
    width: 89vw;
  }
}

.ProductDetail__Fields:after {
  background: linear-gradient(0deg, #eae7dd, #eae7dd 25%, hsla(46, 24%, 89%, 0));
  bottom: 0;
  content: "";
  height: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  transition: opacity .5s $transition-timing-function .5s;
  width: 100%;
}

.ProductDetail__Fields.expanded:after {
  opacity: 0;
}

.ProductDetail__Field {
  display: flex;
  justify-content: space-between;
  //line-height: 1.2;
  min-height: 2.6vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .ProductDetail__Field {
    font-size: 3.2vw;
    min-height: 10vw;
  }

  .ProductDetail__Field span {
    max-width: 42vw;
  }
}

.ProductDetail__Field > span {
  width: 50%;
}

.ProductDetail__Field > span:nth-child(2) {
  text-align: right;
}

.ProductDetail__FieldContent {
  padding-left: 4vw;
  text-align: right;
}
