html, body {
  scroll-behavior: smooth;
}

body {
  background-color: #eae7dd;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;

  &.disableScroll {
    overflow: hidden
  }
}

.main {
  overflow-x: hidden;
}

.anchor {
  scroll-margin-top: 5vw;

  /*&:before {
    content: '';
    display: block;
    position: relative;
    padding-top: 5vw;
    margin-top: -5vw;
    visibility: hidden;
  }*/
}

.fontSans {
  font-family: Graphik, sans-serif;
  font-weight: 400
}

// BOOTSTRAP OVERRIDES

.container-xxl {
  max-width: 1720px;
}
