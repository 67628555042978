// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Colors
$color_white: #fff;
$color_dark:        #9e9a8e;
$color_dark_opacity:rgba(158,154,142,.45);
$color_dark_opacity_15: rgba(158,154,142,.15);
$color_light:       #eae7dd;
$color_light_opacity:rgba(234,231,221,.45);
$color_light_opacity_15: rgba(234,231,221,.15);
$color_light_opacity_20: rgba(234,231,221,.20);
$color_dark_dark:   #4c4b42;
$color_dark_dark_opacity:   rgba(76,75,66,.45);
$color_dark_dark_opacity_15: rgba(76,75,66,.15);
$color_dark_dark_opacity_7: rgba(76,75,66,.7);
$color_evidence:    #ffa529;
$color_border_dark: #727165;
$color_border_light: #eae7dd;
$color_success:     #036803ad;
$color_error:       #dc3545;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #834092;
$pink:    #e72b50;
$red:     #dc3545;
$orange:  #ffa529;
$yellow:  #fab42c;
$green:   #198754;
$green_dark:   #036803ad;
$teal:    #20c997;
$cyan:    #0dcaf0;

$gray-light: #f5f8fa;
$gray: #6d7479;
$gray-dark: #263640;

$gray-100: $gray-light;
$gray-300: #dee2e6;
$gray-400: #c4c4c4;
$gray-600: $gray;
$dark: $gray-dark;

$primary:  $orange;

$min-contrast-ratio: 1.63;

// Fonts
$font-family-main: 'Ivar', serif;
$font-family-headers: 'Ivar', serif;

$font-family-sans-serif:  $font-family-main;

// Columns
$grid-gutter-width: 50px;

// Buttons
$btn-border-radius:           0;
$btn-border-radius-sm:        0;
$btn-border-radius-lg:        0;

//
$input-border-color:    $gray-300;
$input-border-radius:   0;
$input-group-addon-bg:  $gray-100;


// TRANSITIONS
$transition-timing-function: cubic-bezier(.165,.84,.44,1);
