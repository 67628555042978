@import '../variables';

// SELECTED MENU
$pages: about, contacts, categories, contacts, home, mission, partners, partners_locator, products, strategy, standard;

.Nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 998;

  padding: {
    left: 2.5vw;
    right: 2.5vw;
  };

  &.LightMenu .Nav__Menu__Link {
    color: $color_light;

    &:before {
      border-bottom-color: $color_light;
    }

    a, a:hover, a:active{
      color: $color_light;
    }
  }
  //&.DarkMenu .Nav__Menu__Link {
  //  color: $color_dark_dark;
  //
  //  a, a:hover, a:active{
  //    color: $color_dark_dark;
  //  }
  //}

  &__LogoIcon {
    pointer-events: all;
    width: 5.0vw;
    min-width: 60px;
    padding: {
      top: 10px;
      bottom: 10px;
    };
  }

  &__Menu {
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 1.125;

    &.active {
      border-top: 10px solid $color_dark;
      opacity: 1;
      transition-delay: .8s
    }

    &__Link {
      display: inline-block;
      position: relative;
      color: $color_dark_dark;
      letter-spacing: .06em;
      line-height: 1.125;
      text-transform: uppercase;
      text-align: center;
      padding: 0 1rem;
      //font-family: Graphik, sans-serif;
      //cursor: pointer;

      transform: scale(.9);
      transition: all 1s $transition-timing-function;

      //&:hover {
      //  background-color: $color_light_opacity;
      //}

      &:before {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: 0;
        left: calc(50% - 20px);
        width: 2vw;
        height: 100%;
        border-bottom: 1px solid $color_dark;

        transition: all .5s $transition-timing-function;
      }

      &:hover {
        transform: scale(1);

        &:before {
          transform: scale(3);
          opacity: 0;
        }
      }

      a, a:hover, a:active{
        color: $color_dark_dark;
      }
    }
  }

  nav ul {
    margin-bottom: 0;
    overflow: hidden;

    &:hover {
      overflow: visible;
    }

    li {

      > ul {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 50%;
        padding-left: 0;
        margin-top: calc(1.5vw + 1.5rem);
        transform: translateX(-50%);
        transition: all 1s $transition-timing-function;

        li {
          min-width: 100%;
          margin-bottom: 1rem;
          white-space: nowrap;
        }
      }

      &:hover > ul {
        opacity: 1;
        transform: translateX(-50%);

        li {
          transform: revert;
        }
      }
    }
  }

  &__MobileMenu {
    #hamburger-button {
      display: none;
    }

    &__Button {
      &__Container {
        position: fixed;
        top: 0;
        right:5px;
        width: 60px;
        height: 60px;
        padding: 15px;
        z-index: 1000;
        cursor: pointer;
      }

      &__Hamburger {

        &, &:before, &:after {
          display: block;
          background-color: $color_dark_dark;
          position: absolute;
          height: 2px;
          width: 30px;
          transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
          border-radius: 4px;
          top:50%;
          transform: translateY(-50%);

          .Nav.LightMenu & {
            background-color: $color_light;
          }
        }

        &:before {
          content: '';
          margin-top: -8px;
        }

        &:after {
          content: '';
          margin-top: 8px;
        }
      }

      #hamburger-button:checked + &__Container &__Hamburger {
        background: rgba(255, 255, 255, 0);

        &:before {
          background-color: $color_light;
          margin-top: 0;
          transform: rotate(225deg);
        }

        &:after {
          background-color: $color_light;
          margin-top: 0;
          transform: rotate(-225deg);
        }
      }
    }

    &__Container {
      position: fixed;
      z-index: 998;
      background-color: $color_dark;
      top:0;
      left:0;
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);

      #hamburger-button:checked ~ & {
        opacity: .95;
        width: 100vw;
        height: 100vh;

        .Nav__MobileMenu__Content {
          height: 100vh;
        }
      }
    }

    &__Content {
      //position: absolute;
      margin: 0 auto;
      width: 95vw;
      height: 0;
      max-height: 100vh;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__Link {
      font-size: 36px;
      margin: .2em 0;
      text-transform: uppercase;

      &, & label, & a {
        color: $color_light;
        text-decoration: none;
        cursor: pointer;
      }

      & label {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -30px;
          width: 10px;
          height: 10px;
          border-color: $color_light;
          border-style: solid;
          border-width: 2px 2px 0 0;
          transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
          transform: rotate(135deg) translateY(100%);
        }
      }

      & input {
        display: none;

        &:checked {
          & + label {
            &:after {
              right: -28px;
              transform: rotate(-45deg) translateY(-50%);
            }
          }

          & ~ .Nav__MobileMenu__SubMenu {
            opacity: 1;
            max-height: 500px;
          }
        }
      }
    }

    &__SubMenu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);

      .Nav__MobileMenu__Link {
        font-size: 30px;
        text-transform: none;
      }
    }
  }
}


@each $page in $pages {
  body[data-page=#{$page}] {
    .Nav__Menu__Link[data-page=#{$page}] {
      cursor: default;
      transform: scale(1.2);

      &:before {
        border-bottom: none;
      }

      & > a {
        cursor: default;
        pointer-events: none;
      }
    }

    .Nav__MobileMenu__Link[data-page=#{$page}] {
      cursor: default;

      & > a {
        cursor: default;
        pointer-events: none;
      }

      & > a, & > label {
        color: $color_evidence;
      }
    }
  }
}
