@import "../variables";

.ParallaxImage {
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-image: var(--image-src);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(.2);
    transition: all 1.5s $transition-timing-function .2s;
  }

  &.onScreen:before {
    transform: scale(1);
    opacity: 1;
  }
}
