@import "variables";

.SectionBoxImage {
    background-color: $color_dark_dark;
    //height: 300vh;
    overflow: hidden;
    width: 100%;
}

.SectionBoxImage__Container {
    //height: 100vh;
    padding-top: 50px;

    @include media-breakpoint-up(lg) {
        padding-top: 9vw;
    }
}

.SectionBoxImage__ContainerTitle {
    color: $color_light;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
        text-align: left;
        width: 65vw;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-bottom: 5vw;
    }
}

.SectionBoxImage__ContainerSubtitle {
    color: $color_light;
    margin-bottom: 10vw;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(lg) {
        text-align: left;
        width: 65vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

.SectionBoxImage__ContainerImage {
    @include media-breakpoint-up(lg) {
        margin-left: auto;
        width: 65vw;
    }
}

.SectionBoxImage__FirstSection {
    background-color: $color_light;
    //box-sizing: border-box;
    //margin: auto;
    padding: 15px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding: 2.5vw;
        height: 70vw;
    }
}

.SectionBoxImage__FirstSectionCatchphrase {
    color: $color_dark_dark;
    //margin-bottom: 2vw;
    padding: 15px 0;

    @include media-breakpoint-up(lg) {
        padding: 8vw 2vw 8vw;
    }
}

.SectionBoxImage__SecondSectionCatchphrase > * {
    width: 66vw
}

.SectionBoxImage__FirstSectionText {
    color: $color_dark_dark;
    font-size: 16px !important;
    font-weight: 300;
    line-height: 1.2 !important;


    @include media-breakpoint-up(lg) {
        height: 20vw;
        font-size: 1.2vw !important;
        line-height: 1.125 !important;
        column-fill: auto;
        column-gap: 1.4vw;
        columns: 22vw 2;
        margin-left: auto;
        width: 60vw;
    }
}
