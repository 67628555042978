@import "variables";

.TermsOfUse {
  background-color: $color_light;

  @include separators;

  &__Container {
    margin: 4vw 0 4vw;
    display: flex;
  }

  &__Menu {
    //background-color: #763235;
    align-items: center;
    border-right: .07vw solid $color_dark_dark_opacity_15;
    box-sizing: border-box;
    color: $color_dark_dark;
    height: calc(100vh - 150px);
    width: 30%;
    //padding-top: 50vh;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //margin: auto;
    top: 0;
    position: relative;
    padding: 25vh 10%;

    @media (max-width: 1024px) and (orientation: portrait) {
      height: calc(100vh - 80px);
      padding: 0 0 0 25px;
    }

    &Link {
      display: block;
      font-size: 2vw;
      line-height: 4vw;
      color: $color_dark_dark;
      opacity: .5;
      position: relative;
      transition: opacity .6s ease;
      text-decoration-line: none;

      @media (max-width: 1024px) and (orientation: portrait) {
        font-size: 16px;
      }

      &.isCurrent:before {
        content: "";
        position: absolute;
        border-color: $color_evidence;
        border-style: solid;
        //border-radius: 4px;
        border-width: 0 2px 2px 0;
        width: 0.2em;
        height: 0.2em;
        top: 50%;
        transform: translateY(-50%) translateX(-.8em) rotate(-45deg);
      }

      &:hover {
        color: $color_dark_dark;
        opacity: 1
      }

      &.isCurrent, &:hover.isCurrent {
        color: $color_evidence;
        opacity: 1
      }
    }
  }

  &__Content {
    padding-right: 4vw;
    padding-left: 4vw;
    width: 70%;
    text-align: justify;

    @media (max-width: 1024px) and (orientation: portrait) {
      margin-left: auto;
      padding-right: 25px;
      padding-left: 25px;
      width: 80%;
    }

    &Info {
      margin-top: 3vw;
    }

    &Title {
      border-bottom: .07vw solid $color_dark_dark_opacity_15;
      //margin-top: 8vw;
      padding-bottom: 2vw;
      color: $color_evidence
    }

    &Content {
      margin-top: 4vw;
      font-family: Graphik, sans-serif;
      font-size: 1vw;
      //font-weight: 400;

      @media (max-width: 1024px) and (orientation: portrait) {
          font-size: 12px;
      }
    }
  }
}
