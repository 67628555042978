@font-face {
  font-display: swap;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/graphik/GraphikRegular.otf");
}

@font-face {
  font-display: swap;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/graphik/GraphikMedium.otf");
}
