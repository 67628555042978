@import "variables";

.MultiColumn {
  background-color: $color_dark;
  padding: 4vw;
  @include separators;
}

.MultiColumn__Container {
  padding-bottom: 6vw;
}

.MultiColumn__Header {

}

.MultiColumn__Phrase {
  padding-bottom: 6vw;
  padding-top: 4vw;
  font-size: 40px !important;
  color: $color_light;

  @include media-breakpoint-up(md) {
    font-size: 6vw !important;
  }
}

.MultiColumn__Article {
  column-count: 1;
  column-gap: 8vw;
  column-rule: .07vw solid $color_light_opacity_15;

  color: $color_light;
  text-align: left;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    column-count: 2;
    font-size: 1.5vw;
  }
}

.MultiColumn__Article>*>a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $color_dark_dark_opacity_7;
  }
}
