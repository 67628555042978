@import 'variables';

.BasePage {
  background-color: $color_light;
  color: $color_dark;
  height: 500vh;
  position: relative;
}

.BasePage__Container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: sticky;
  top: 0;
}

.BasePage__Header {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
}