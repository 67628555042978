@import "variables";

.Categories {
  background-color: $color_light;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  width: 100%;

  &__Header {
    align-items: center;
    background-color: $color_dark;
    color: $color-light;
    display: flex;
    margin: auto;
    padding: 65px 2.5vw 1vw;
    width: 100%;

    @include media-breakpoint-up(xl) {
      padding-top: 4vw;
    }
  }

  &__Type {
    font-size: 28px !important;

    @include media-breakpoint-up(sm) {
      font-size: 4vw !important;
    }

    &Container {
      position: relative;
      width: auto;
    }
  }

  &__StickyFilters {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 78%;
    z-index: 1;

    &List {
      display: flex;

      padding-left: 1vw;
      flex-wrap: wrap;
      height: 100%;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      margin-top: 16px;
    }
  }

  &__List {
    display: flex;
    flex-wrap: wrap;
    min-height: 25vw;
    opacity: 1;
    padding-top: 2vw;
    padding-right: 1vw;
    padding-left: 1vw;
    //padding: 4vw 0 2vw;
    transition: opacity .9s $transition-timing-function 0s;
    width: 100%;

    &Item {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 80vw;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 100%;
      background-color: $color_dark_opacity;
      margin-left: 1vw;
      margin-right: 1vw;
      margin-bottom: 2vw;

      @include media-breakpoint-up(sm) {
        height: 33vw;
        width: calc(50% - 2vw)
      }

      @include media-breakpoint-up(lg) {
        width: calc(33.333333% - 2vw);
      }

      @include media-breakpoint-up(xxl) {
        width: calc(25% - 2vw);
      }

      &:after {
        content: '';
        position: absolute;
        opacity: .4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-dark_dark;
        z-index: 1;
        transition: all .6s $transition-timing-function;
      }

      &Bg {
        opacity: .8;
        filter: grayscale(1);
        height: 100%;
        -o-object-fit: fill;
        object-fit: cover;
        position: absolute;
        transform: scale(1);
        transition: all .6s $transition-timing-function;
        width: 100%;
        z-index: 0;
      }

      &:hover:after, &.active:after {
        opacity: 0;
      }

      &:hover &Bg, &.active &Bg {
        filter: grayscale(0);
        opacity: 100%;
        transform: scale(1.2);
      }

      &Logo {
        height: 4vw;
        position: relative;
        z-index: 2;
      }

      &Title {
        font-size: 8vw;
        color: $color_light;
        position: relative;
        z-index: 2;
        text-align: center;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);

        @include media-breakpoint-up(sm) {
          font-size: 5vw;
        }

        @include media-breakpoint-up(lg) {
          font-size: 3vw;
        }
      }
    }
  }
}
