@import 'variables';

.SectionBox {
  background-color: $color_dark_dark;
  box-sizing: border-box;
  height: 20vw;
  width: 100%;

  @include separators;
}

.SectionBox__Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
  width: 100%
}

.SectionBox__Text {
  color: $color_light;
  text-align: center;
}


