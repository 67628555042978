@import "variables";

.ContactsMessageUs {
  display: flex;
  @include separators;
}

.ContactsMessageUs__Container {
  background-color: $color_light;
  //padding-bottom: 4vw;
  padding-top: 10vw;
  width: 100%;
  height: 100vh;
  display: flex;
}

.ContactsMessageUs__Info {
  color: $color_dark_dark;
  //width: 50%;
  padding-left: 6vw;
}

.ContactsMessageUs__InfoTitle {
  color: $color_dark_dark;
  text-align: center;
  padding: 4vw 0 4vw;
}

.ContactsMessageUs__InfoText {
  color: $color_dark_dark;
  text-align: left;
  font-family: Graphik, sans-serif;
  font-size: 1vw;
  font-weight: 500;
}

.form-wrapper {
  width: 40vw;
  margin: 4vw auto;
}

.ContactsMessageUs__FormRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vw;
  width: 100%
}

.ContactsMessageUs__FormInputTextareaContainer {
  position: relative;
  margin-top: 2vw;
}

.ContactsMessageUs__FormInputContainer.focus:after, .ContactsMessageUs__FormInputTextareaContainer.focus:after {
  transform: none
}

.ContactsMessageUs__FormInputTextarea {
  background: none;
  border: none;
  border-bottom: .07vw solid $color_dark_dark_opacity_15;
  caret-color: $color_dark_dark;

  padding-top: 2vw;
  padding-bottom: 1vw;

  letter-spacing: .01em;
  line-height: 1;
  width: 100%;

  color: $color_dark_dark;
  text-align: left;
  font-family: Graphik, sans-serif;
  font-size: 1vw;
  font-weight: 500;

  &:focus {
    outline: none;
    border-color: $color_dark;
    box-shadow: 0 1px 0 0 $color_dark;
  }
}

.form-group {
  position: relative;
  margin-top: 2vw;
}

.form-label {
  font-family: Ivar Display Condensed, serif;
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: .01em;

  position: absolute;
  left: 0;
  top: 10px;
  color: $color_dark_dark;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .form-label {
  transform: translate3d(0, -80%, 0) scale(.6)
}

.form-input {
  background: none;
  position: relative;
  width: 100%;
  outline: 0;
  border: 0;
  transition: box-shadow 150ms ease-out;

  box-shadow: 0 1px 0 0 $color_dark_opacity;
  padding-top: 2vw;
  padding-bottom: 1vw;

  color: $color_dark_dark;
  text-align: left;
  font-family: Graphik, sans-serif;
  font-size: 1vw;
  font-weight: 500;

  &:focus {
    box-shadow: 0 1px 0 0 $color_dark;
  }
}

.form-input.filled {
  box-shadow: 0 2px 0 0 $color_dark_dark;
}

.ContactsMessageUs__FormInputTextarea.filled {
  box-shadow: 0 2px 0 0 $color_dark_dark;
}

.ContactsMessageUs__FormSubmit {
  margin-top: 4vw;
}

.ContactsMessageUs__FormMessages {
  height: 2vw;
  margin-top: 2vw;

}

.ContactsMessageUs__FormMessage--Success {
  color: $green_dark
}

.ContactsMessageUs__FormMessage--Error {
  color: $red
}

.Contact__FormMessage--Loading {
  color: $color_dark_dark;
}
