@import "variables";

// FILTER SLIDING VIEW
.SlidingPanel {
  background-color: $color_dark_dark_opacity_15;
  cursor: pointer;
  height: 100vh;
  right: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: all .6s cubic-bezier(.86, 0, .12, 1) 0s;
  z-index: 999;
  width: 32.2vw;

  &.ProductDetail {
    width: 59.2vw;
  }

  &.PartnerDetail {
    width: 59.2vw;
  }

  &.CategoryDetail {
    width: 59.2vw;
  }
}

.SlidingPanel.isOpen {
  opacity: 1;
  pointer-events: all;
  transform: none;
}

.SlidingPanel.isOpen .SlidingPanel__Container {
  transform: none;
  transition-delay: 0.1s;
}

.SlidingPanel__Container {
  background-color: $color_light;
  cursor: default;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition-delay: 0s;
  transition: transform 1.2s cubic-bezier(.86, 0, .12, 1);
  width: calc(32.2vw - 1px);

  .SlidingPanel.ProductDetail & {
    width: calc(59.2vw - 1px);
  }

  .SlidingPanel.PartnerDetail & {
    width: calc(59.2vw - 1px);
  }

  .SlidingPanel.CategoryDetail & {
    width: calc(59.2vw - 1px);
  }
}


.SlidingPanel__Close {
  position: relative;
  background-color: $color_dark;
  border-radius: 1.5vw;
  cursor: pointer;
  height: 3vw;
  left: 2vw;
  top: 1vw;
  width: 3vw;

  &:after,
  &:before {
    position: absolute;
    content: '';
    left: calc(50% - .4vw);
    top: 50%;
    width: .8vw;
    height: .07vw;
    background-color: $color_light;

    transition: transform .5s ease-in-out;
  }

  &:before {
    transform: rotate(135deg);
  }

  &:after {
    transform: rotate(-135deg);
  }

}

.SlidingPanel__Close:hover:before {
  transform: none; //rotate(0deg);
}

.SlidingPanel__Close:hover:after {
  transform: none; //rotate(90deg);
}
