@import 'variables';

.SectionPage {
  background-color: $color_dark_dark;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage {
    overflow: hidden;
  }
}

.SectionPage__StickyContainer {
  height: 200vh;
  position: relative;
}

.SectionPage__Sticky {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
}

.SectionPage__SpritesContainer {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.SectionPage__Sprites {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__Sprites {
    transform: translate3d(-60%, 0, 0);
    width: 250%;
  }
}

.SectionPage__Frame {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.SectionPage__FrameBorderLeft, .SectionPage__FrameBorderRight {
  background-color: $color_dark_dark;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 38vw;
}

.SectionPage__FrameBorderRight {
  right: 0;
}

.SectionPage__FrameBorderBottom, .SectionPage__FrameBorderTop {
  background-color: $color_dark_dark;
  height: 32vh;
  left: 0;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 16vh;
  }
}

.SectionPage__FrameBorderBottom {
  bottom: 0;
}

.SectionPage__HeroHeader {
  align-items: center;
  color: $color_light;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2
}

@media (orientation: portrait)and (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__HeroHeaderTitle {
    font-family: Graphik, sans-serif;
    font-size: 2.7vw;
    font-weight: 500;
    letter-spacing: .06em;
    line-height: 2.2;
    text-transform: uppercase
  }
}

.SectionPage__HeroHeaderCatchphrase {
  margin-top: 2vw;
  text-align: center;
  width: 53vw;
  text-shadow: 0 0 8px rgba(0,0,0,0.6);
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__HeroHeaderCatchphrase {
    margin-top: 5vw;
    width: 74vw
  }
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__HeroHeaderCatchphrase {
    font-size: 16vw;
    font-weight: 300;
    letter-spacing: -.05em;
    line-height: .9
  }
}


.SectionPage__Card {
  background-color: $white;
  border-radius: .4vw;
  opacity: 0;
  //bottom:3vw;
  box-sizing: border-box;
  //cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 15vw;
  justify-content: space-between;
  padding: 2vw;
  position: absolute;
  bottom: 25vh;
  left: 50%;
  width: 18vw;
  margin: -7.5vw 0 0 -9vw;
  will-change: transform;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__Card {
    display: none
  }
}

.SectionPage__CardSubtitle {
  color: $color_dark_dark_opacity
}

.SectionPage__CardTitle {
  color: $color_dark_dark;
  width: 10vw
}

.SectionPage__Content {
  background-color: $color_dark;
  overflow: hidden;
  //display: flex;
  height: 100vh;
  width: 100%;
}

/*@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__Content {
    padding-bottom: 48.0000000006vw;
    padding-top: 48.0000000006vw
  }
}*/

.SectionPage__ContentCatchphrase {
  color: $color_light;
  //margin-bottom: 8vw;
  padding: 8vw 15vw  8vw;
  position: relative;
  text-align: center;
  width: 100%
}

.SectionPage__ContentCard {
  //background-color: $color_dark;
  margin-bottom: 8vw;
  overflow: hidden;
  padding: 8vw 0 8vw 3vw;
  position: relative;
  width: 40vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentCatchphrase {
    box-sizing: border-box;
    margin-bottom: 18.7vw;
    padding: 18.7vw 5.3vw;
    width: 100%
  }
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentCatchphrase {
    font-size: 13vw;
    font-weight: 300;
    line-height: 1.125
  }
}

/*.SectionPage__ContentCatchphrase:after, .SectionPage__ContentCatchphrase:before {
  background-color: $color_dark_dark_opacity_15;
  content: "";
  height: .07vw;
  left: 2vw;
  position: absolute;
  top: 0;
  width: 95vw
}*/

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentCatchphrase:after, .SectionPage__ContentCatchphrase:before {
    height: .27vw;
    left: 5.3vw;
    width: 89.3vw
  }
}

.SectionPage__ContentCatchphrase:after {
  bottom: 0;
  top: auto
}

.SectionPage__ContentText {
  color: $color_dark_dark;
  margin-bottom: 4.7vw;
  margin-left: 50.7vw;
  width: 22vw
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentText {
    margin-bottom: 16vw;
    margin-left: 20vw;
    width: 72vw
  }
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentText {
    font-family: Graphik, sans-serif;
    font-size: 3.7vw;
    font-weight: 400;
    line-height: 2.57
  }
}

.SectionPage__ContentButton {
  align-items: center;
  background-color: $color_light;
  border-radius: 7vw;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 1vw;
  justify-content: space-between;
  margin-top: 1vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentButton {
    height: 14vw;
    margin-left: 20vw;
    padding: 4vw 7vw
  }
}

.SectionPage__ContentButtonLabel {
  color: $color_light;
  display: block;
  margin-right: .7vw
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentButtonLabel {
    margin-right: 3vw
  }
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentButtonLabel {
    font-size: 2.7vw;
    font-weight: 500;
    letter-spacing: .06em;
    line-height: 2.2;
    text-transform: uppercase
  }
}

.SectionPage__ContentButtonArrow {
  stroke: $color_light;
  transition: transform .3s $transition-timing-function;
  width: .7vw;
  will-change: transform
}

@media (max-width: 1024px)and (orientation: portrait) {
  .SectionPage__ContentButtonArrow {
    width: 3vw
  }
}

.SectionPage__ContentButton:hover .SectionPage__ContentButtonArrow {
  transform: translate3d(40%, 0, 0)
}
