@import "variables";

//.CategoryDetail {
//  &:before, &:after {
//    content: '';
//    display: block;
//    background-color: $color-dark;
//    width: 100%;
//    height: 2vw;
//  }
//
//  &:first-of-type:before {
//    content: none;
//  }
//
//  &:last-of-type:after {
//    content: none;
//  }
//}

.CategoryDetail__Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .carousel-inner {
    min-height: 50vh;

    @include media-breakpoint-up(lg) {
      min-height: 80vh;
    }

    .carousel-item img {
      object-fit: cover;
      height: 50vh;

      @include media-breakpoint-up(lg) {
        height: 80vh;
      }
    }
  }
}

.CategoryDetail__FieldsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  align-items: center;

  @include media-breakpoint-up(md) {
    min-height: 50vh;
    padding: 1.2vw 2vw;
  }
}

.CategoryDetail__EvidenceText {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: 1.2vw;
  }
}

.CategoryDetail__SubTitle {
  padding: 15px;
  text-align: center;
  width: 100%;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    padding: 5vw 2.5vw 5vw;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.2vw;
  }
}

//@media (max-width: 1024px)and (orientation: portrait) {
//  .CategoryDetail__SubTitle {
//    display: none
//  }
//}

.CategoryDetail__BrandLogo {
  height: 100px;
  left: auto;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden;
  //position: absolute;
  right: 0;
  width: 200px;
}

.CategoryDetail__Image {
  height: auto;
  //margin-bottom: 1vw;
  padding: 1vw 2vw 0 1vw;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  width: 50vw;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .CategoryDetail__Image {
    width: 100%;
    margin-bottom: 1vw
  }
}

.CategoryDetail__Infos {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%
}

//@media (max-width: 1024px)and (orientation: portrait) {
//  .CategoryDetail__Infos {
//    align-items: flex-start;
//    padding: 4vw 5vw;
//    width: 100%
//  }
//}

.CategoryDetail__Name {
  padding-top: 5vw;
  text-align: center;
  width: 100%
}

.CategoryDetailWide__Name {
  text-align: center;
  width: 100%;
  font-size: 32px;
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    font-size: 6.7vw;
  }
}

//@media (max-width: 1024px)and (orientation: portrait) {
//  .CategoryDetail__Name {
//    display: none
//  }
//}

.CategoryDetail__Brand {
  margin-bottom: 1vw;
  text-align: center;
  width: 100%
}

//@media (max-width: 1024px)and (orientation: portrait) {
//  .CategoryDetail__Brand {
//    display: none
//  }
//}

.CategoryDetail__Text {

  @include media-breakpoint-up(md) {
    min-height: 50vh;
  }
}

.CategoryDetail__CompanyFields {
  border-bottom: thin solid $color_dark_dark_opacity_15;
  border-top: thin solid $color_dark_dark_opacity_15;
  height: auto;
  overflow: hidden;
  padding: 2vw 2vw;
  position: relative;
  transition: height .8s cubic-bezier(.86, 0, .12, 1);
  width: 40%
}

.CategoryDetail__Fields {
  border-bottom: thin solid $color_dark_dark_opacity_15;
  border-top: thin solid $color_dark_dark_opacity_15;
  height: auto;
  //margin-bottom: 7vw;
  overflow: hidden;
  padding: 2vw 3vw;
  position: relative;
  transition: height .8s cubic-bezier(.86, 0, .12, 1);
  width: 60%
}

@media (max-width: 1024px) and (orientation: portrait) {
  .CategoryDetail__Fields {
    height: 58vw;
    margin-bottom: 0;
    order: 3;
    padding: 7.5vw 0 15vw;
    width: 89vw
  }
}

.CategoryDetail__Fields:after {
  background: linear-gradient(0deg, #eae7dd, #eae7dd 25%, hsla(46, 24%, 89%, 0));
  bottom: 0;
  content: "";
  height: 0%;
  left: 0;
  opacity: 1;
  position: absolute;
  transition: opacity .5s $transition-timing-function .5s;
  width: 100%
}

.CategoryDetail__Fields.expanded:after {
  opacity: 0
}

.CategoryDetail__Field {
  display: flex;
  justify-content: space-between;
  //line-height: 1.2;
  min-height: 2.6vw
}

.CategoryDetail__FieldContent {
  padding-left: 4vw;
}

.CategoryDetailWide__Image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1 1 auto;

  &Container {
    display: flex;
    align-items: stretch;
  }
}
