@import 'variables';

.SectionSplitted {
  @include separators;

  height: 200vh;

  &__StickyContainer {
    height: 50%;
    width: 100%;
    overflow-x: hidden;
  }

  &__HeaderStickyFirst {
    display: flex;
    height: 100%;
    overflow: hidden;
    top: 0;
    width: 100%;
  }

  &__Header {
    align-items: center;
    color: #eae7dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &Title {
      font-size: 50px;
      line-height: 1.2;
      margin-top: 8vh;
      text-align: center;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);

      @include media-breakpoint-up(md) {
        font-size: 8vw;
      }
    }
  }

  &__First {
    &Frame {
      height: 100%;
      width: 100%;
      transform: scale(1, 1);

      @include media-breakpoint-up(md) {
        &Border {
          &Left, &Right {
            background-color: $color_light;
            height: 100%;
            position: absolute;
            top: 0;
            width: 25%;
          }

          &Bottom, &Top {
            background-color: $color_light;
            height: 16%;
            left: 0;
            position: absolute;
            width: 100%
          }

          &Right {
            right: 0;
          }

          &Bottom {
            bottom: 0;
          }
        }
      }
    }

    &Sprites {
      background-color: #eae7dd;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 100%;
      transform: scale(1);
      transform-origin: center;
      width: 100%;

      &Container {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        transform-origin: center;

        &.onScreen .Homepage__FirstSprites {
          transform: none;
          transition: transform 1.5s $transition-timing-function;
        }
      }
    }

    &Section {
      display: block;
      position: absolute;
      left: 100%;
      height: 100%;
      width: 100%;

      @include media-breakpoint-up(md) {
        left: 50%;
        width: 50%;
      }

      &--Mobile {
        display: none
      }

      &Title {
        opacity: 1;
        pointer-events: none;
        text-align: center;
        margin-top: 12vw;
        width: 100%;
      }
    }
  }

  &__ScrollCta {
    display: none;
    position: absolute;
    right: 0;
    bottom: 25%;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
