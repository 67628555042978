@import '../../variables';

:root {
  --cc-btn-primary-bg: #{$color_evidence};
  --cc-btn-secondary-text: #{$color_white};
  --cc-font-family: Graphik, sans-serif;
  --cc-cookie-category-block-bg: #{$color_evidence}AA;
  --cc-cookie-category-block-bg-hover: #{$color_evidence}AA;
}

.cc_div .cloud #c-inr {
  display: flex;
  flex-direction: column;
}

.cc_div .cloud #c-inr-i {
  width: 100%;
}

.cc_div .cloud #c-bns {
  display: flex;
  justify-content: space-around;
}

#cm.cloud .c-bn:first-child {
  margin-top: 8px;
}

#cm.cloud .c-bn {
  width: 40%;
  flex: 0 0 auto;
}

#c-ttl, #s-ttl, .b-tl,  {
  font-family: Ivar Display Condensed, serif !important;
  text-transform: uppercase;

  &:hover {
    text-transform: uppercase;
  }
}

#cm {
  background-color: $color_white !important;
}

.c-bn {
  background-color: $color_evidence !important;
  color: white !important;
}

#c-s-bn, #s-cnt #s-rall-bn {
  background-color: $color_white !important;
  color: $color_evidence !important;
  border: 1px solid $color_evidence;
}

#s-sv-bn {
  background-color: $color_dark !important;
  color: $gray-light !important;
}

.c-bn {
  &:hover {
    box-shadow: inset 0 0 4px #0004;
  }
}

.cc_div {
  .cc-link {
    white-space: nowrap;
    font-weight: 500;
  }
}
