@font-face {
  font-display: swap;
  font-family: Ivar;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Ivar/Light.woff2");
}

@font-face {
  font-display: swap;
  font-family: Ivar;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Ivar/Regular.woff2");
}

