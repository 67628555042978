@import 'variables';

.SectionSlider {
  align-items: center;
  background-color: $color_dark;
  color: $color_light;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  top:0;
  width: 100%;

  @include separators;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  .ParallaxImage {
    max-height: 50%;

    @include media-breakpoint-up(md) {
      max-height: 100%;
    }
  }
}

.SectionSlider__Background {
  -o-object-fit: fill ;
  object-fit: fill;
  position: absolute;
  height: 100%;
  z-index: -1;
}

.SectionSlider__Box {
  bottom: 0;
  display: block;
  height: auto;
  //margin: auto 0 0 auto;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;

  @include media-breakpoint-up(md) {
    width: 50vw;
  }
}

.SliderBox__Arrows {
  display: flex;
  justify-content: space-around;
  height: 50px;
  width: 100%;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    height: 3.5vw;
    width: 8vw;
  }
}

.SliderBox__Arrow {
  align-items: center;
  border: 0.07vw solid hsla(46,24%,89%,.2);
  border-radius: 25px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: border .6s $transition-timing-function;
  width: 30%;

  @include media-breakpoint-down(md) {
    svg {
      width: 40%;
    }
  }

  @include media-breakpoint-up(md) {
    border-radius: 2.8vw;
    width: 3.8vw;
  }
}

.SliderBox__Arrow:hover {
  border: .07vw solid #eae7dd
}

.SliderBox__Arrow--Left {
  padding-left: 20px;
  padding-right: 20px;
  right: 20px;
}

.SliderBox__Arrow--Right {
  padding-left: 20px;
  padding-right: 20px;
  left: 20px;
  right: 20px;
}

.SliderBox {
  background-color: $color_dark;
}

.SliderBox__Container {

}

.SliderBox__Border {
  visibility: hidden;
  background-color: hsla(46,24%,89%,.15);
  height: .07vw;
  margin-top: 2vh;
  width: 100%
}

.SliderBox__Controls {
  padding: 1em;
}

.SliderBox__Item {
  padding-top: 2vw;
  height: calc(30vh + 22vw);
}

.SliderBox__Title {
  align-content: center;
  //height: 48vh;
  padding-left: 4vw;
  word-spacing: 100vw;

  &.h3 {
    font-size: 48px;

    @include media-breakpoint-up(md) {
      font-size: 6.7vw;
    }
  }
}

.SliderBox__Text {
  background-color: $color_evidence;
  border-radius: 0 12vh 12vh 0;
  align-content: center;
  font-size: 16px !important;
  font-family: Graphik, sans-serif;
  padding: 4vw;
  transition: opacity 1.6s $transition-timing-function 1s;
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;

  @include media-breakpoint-up(md) {
    height: 12vw;
    width: 40vw;
    padding: 1vw 4vw;
    font-size: 1.8vw !important;
  }
   p {
     margin-bottom: 0;
   }
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 1s;
}

