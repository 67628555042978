@import "variables";
@import "mixins";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/animate.css/animate";

@import "fonts/ivar";
@import "fonts/graphik";

@import 'app';
@import "base_page";

@import "modules/nav_menu";
@import "modules/button";
@import "modules/parallax_image";

@import "components/teaser";

@import "animation";
@import "categories";
@import "categories_detail";
@import "comparison";
@import "contacts";
@import "contacts_messageus";
@import "product_detail";
@import "email";
@import "filter_products";
@import "founder";
@import "gallery";
@import "location";
//@import "navigator";
@import "multicolumn_article";
@import "partners";
@import "partners_detail";
@import "partners_rules";
@import "products";
@import "scroll_cta";
@import "section_box";
@import "section_box_button";
@import "section_box_image";
@import "section_footer";
@import "section_slider";
@import "section_slogan";
@import "section_splitted";
@import "section_page";
@import "sliding_panel";
@import "snapping";
@import "terms_of_use";
@import "top_spacer";
@import "vendors/vanilla_cookieconsent/cookieconsent";

.h1 {
  font-size: 10vw;
  line-height: .8
}

.h2 {
  font-size: 8vw;
  line-height: 1.2
}

.h3 {
  font-size: 6.7vw;
  line-height: 1
}

//.h3,.h4,.h5 {
//  //font-weight: 300
//}

.h4 {
  font-size: 4vw;
  line-height: 1
}

.h5 {
  font-size: 3vw;
  line-height: 1
}

.bodySerif {
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1
}

.bodySerifSmall {
  font-size: 1.8vw;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1
}

//.bodySerif,.bodySerifSmall {
//  //font-family: Ivar Display Condensed,serif
//}

.caps {
  font-size: .8vw;
  letter-spacing: .02em;
  line-height: 2
}

.caps, .capsSmall {
  font-family: Graphik, sans-serif;
  font-weight: 400;
  text-transform: uppercase
}

.capsSmall {
  font-size: .7vw;
  letter-spacing: .06em;
  line-height: 1.6em;
}

ol,ul {
  list-style: none
}

.bodySans {
  font-size: 1vw;
  line-height: 2.25
}

.bodySans, .bodySansSmall {
  font-family: Graphik, sans-serif;
  font-weight: 400
}

.bodySansSmall {
  font-size: .9vw;
  line-height: 1.7
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  position: relative;
}


.line {
  visibility: hidden;
  width: 2px;
  height: 4000px;
  position: absolute;
  left: 400px;
  top: 0px;
  background-color: #777;
}

header .name {
  color: white;
}
/*
.title {
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 40px;
}*/
/*footer {
  position: fixed;
  right: 0px;
  bottom: 0px;
  padding: 6px 10px 10px 12px;
  border-top-left-radius: 26px;
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
}*/

/*.end {
  position: absolute;
  top: 2400px;
  transform: translateY(-100%);
  font-size: 30px;
  color: white;
}*/

.light button {
  color: var(--light);
  background-image: linear-gradient(to bottom, #575757, #414141);
}

ul {
  padding: 0;
  list-style-type: none;
}

.centered-box {
  text-align: center;
}

.nav-menu .menu-item {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.header-background {
  background-color: #000000;//#717171;
}

.header-main-area {
  height: 70px;
  align-items: center;
  justify-content: space-between;
}

.bd-navbar {
  padding: 0.75rem 0;
  background-color: transparent;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15), inset 0 -1px 0 rgba(255,255,255,0.15);
}




// IE
input[type=search]::-ms-clear, input[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0
}

// CHROME - SAFARI
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none
}
