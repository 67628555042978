@import "variables";

.Products {
  background-color: $color_light;
  box-sizing: border-box;
  //margin-left: 2vw;
  //margin-right: 2vw;
  padding: 8vw 0vw 4vw;
  position: relative;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products {
    margin: auto;
    padding: 5vw 5vw;
    width: 100%;
  }
}

.Products__Header {
  align-items: center;
  border-bottom: .07vw solid $color_dark_dark_opacity_15;
  border-top: .07vw solid $color_dark_dark_opacity_15;
  box-sizing: border-box;
  display: flex;
  //flex-wrap: wrap;
  height: 6vw;
  //justify-content: space-between;
  margin: auto;
  padding-left: 2.5vw;
  padding-right: 3vw;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__Header {
    border-top: none;
    height: 3vw;
    margin: 1vw;
    //min-height: 6vw;
    //padding: 16vw 0 4vw;
  }
}

.Products__TypeContainer {
  //overflow: hidden;
  position: relative;
  width: auto;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__TypeContainer {

  }
}

.Products__Type {
  color: $color_dark_dark;
  left: 0;
  top: 0;
}

.Products__List {
  //border-top: .07vw solid $color_dark_dark_opacity_15;
  //box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-height: 25vw;
  //justify-content: space-between;
  opacity: 1;
  padding: 2vw 0 2vw;
  transition: opacity .9s $transition-timing-function 0s;
  width: 100%;
}

/*.Products__List.onScreen {
  border: none;
  opacity: 1;
  transition: opacity 1.5s $transition-timing-function .75s
}*/

.CardProduct {
  cursor: pointer;
  height: auto;
  margin-left: 2.3vw;
  margin-bottom: 2vw;
  width: 22vw;
  overflow: hidden;
}

.CardProduct__Image {
  height: 16vw;
  margin-bottom: 1vw;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  width: 22vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .CardProduct__Image {
    height: 128vw;
    margin-bottom: 5vw;
  }
}

.CardProduct__Name {
  text-align: center;
  color: $color_dark_dark;
  font-size: 1.5vw;
  line-height: 1.09;
  width: 22vw;
  white-space: nowrap;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .CardProduct__Name {
    color: $color_dark;
    font-size: 5vw;
  }
}

.Products__StickyFilters {
  align-items: center;
  display: flex;
  justify-content: center;
  //height: 3vw;
  //left: 2vw;
  //margin-bottom: 7vw;
  //margin-top: -4vw;
  //margin-left: 46.5vw;
  //position: -webkit-sticky;
  //position: sticky;
  //top: 6vw;
  width: 78%;
  z-index: 1;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .Products__StickyFilters {
    //height: auto;
    //min-height: 11.7vw;
    //top: 21vw
  }
}

.Products__StickyFiltersOpen {
  background-color: $color_dark;
  border-radius: 7vw;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  //align-content: center;
  //padding: 0 0vw 0 0.8vw;
  width: 7.2vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__StickyFiltersOpen {
    display: none;
  }
}

.Products__StickyFiltersOpenLabel {
  color: $color_light;
  font-size: 1.2vw;
  line-height: 3vw;
  text-transform: uppercase;
}

.Products__StickyFiltersOpenIcon {
  stroke: $color_light;
  margin-top: -.3vw;
  transition: transform .8s $transition-timing-function;
  width: .6vw;
  will-change: transform;
}

.Products__StickyFiltersOpen:hover .Products__StickyFiltersOpenIcon {
  transform: rotate(90deg);
}

.Products__StickyFiltersList {
  display: flex;

  padding-left: 1vw;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  margin-top: 16px;
}

.Products__StickyFilterButton {
  background-color: transparent;
  border: none;
  transition: var(--bs-navbar-toggler-transition);
  width: 100%;
}

.Products__StickyFiltersItem {
  align-items: center;
  background-color: $color_dark_opacity;
  border-radius: 3vw;
  color: $color_dark_dark;
  cursor: pointer;
  display: flex;

  //margin-bottom: .7vw;
  //margin-left: 1vw;
  //margin-right: .7vw;
  padding: .7vw 1.5vw;
}

.Products__StickyFiltersItemCross {
  margin-left: .7vw;
  margin-top: -.1vw;
  transform: rotate(45deg);
  width: .8vw;
}

// PAGINATION
.Products__Pagination {
  align-items: center;
  border-bottom: .07vw solid $color_dark_dark_opacity_15;
  border-top: .07vw solid $color_dark_dark_opacity_15;
  display: flex;
  height: 6vw;
  //justify-content: space-between;
  //padding-left: 30vw;
  //padding-right: 30vw;
  width: 100%;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__Pagination {
    height: 52vw;
    margin-bottom: 21vw;
    margin-top: 26.6vw;
  }
}

.Products__PaginationArrowContainer {
  height: 2.5vw;
  width: 6vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__PaginationArrowContainer {
    width: 6vw;
  }
}

.Products__PaginationArrow {
  display: block;
  margin: auto;
  border-radius: 1.25vw;
  color: $color_dark_dark;
  cursor: pointer;
  fill: none;
  height: 2.5vw;
  line-height: 2.5vw;
  pointer-events: none;
  text-align: center;
  transition: color .6s $transition-timing-function, background .6s $transition-timing-function;
  width: 2.5vw;

}

.Products__PaginationArrow.active:hover {
  //background-color: hsla(45, 8%, 59%, .5);
  //color: $color_light;
}

.Products__PaginationArrow.active>svg {
  fill: $color_dark_dark;
  pointer-events: auto;
}

.Products__PaginationArrow--Prev {
  text-align: center;
  &:hover>svg {
    transform: translate3d(-0.7vw,0,0);
  }
}

.Products__PaginationArrow--Next {
  text-align: center;
  &:hover>svg {
    transform: translate3d(+0.7vw,0,0);
  }
}

.Products__PaginationLists {
  //align-items: center;
  //display: flex
  display: flex;
  justify-content: center;
  width:100%;
}

.Products__PaginationList {
  display: flex;
  margin: auto;
  //justify-content: space-between;
  //padding-left: 40vw;
  width: auto;
}

.Products__PaginationItem {
  border-color: hsla(45, 8%, 59%, .5);
  border-radius: 1.25vw;
  border-style: solid;
  border-width: 1px;
  color: $color_dark_dark;
  cursor: pointer;
  height: 2.5vw;
  margin-right: 0.3vw;
  text-align: center;
  transition: all .6s $transition-timing-function;//, background .6s $transition-timing-function;
  width: 2.5vw;
  display:table-cell;
  vertical-align: middle;
  &:hover {
    background-color: hsla(45, 8%, 59%, .5);
    color: $color_light;
  }
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__PaginationItem {
    border-radius: 5.3vw;
    font-size: 2.6vw;
    height: 9.6vw;
    line-height: 9.6vw;
    width: 9.6vw;
  }
}

.Products__PaginationItem.current {
  background-color: $color_dark;
  color: $color_light;
  cursor: auto;
  pointer-events: none;
  width: 4vw;
}

.Products__PaginationSeparator {
  background-color: $color_dark_dark_opacity_15;
  height: .07vw;
  margin-left: 4vw;
  margin-right: 1.1vw;
  width: 5vw;
}

@media (max-width: 1024px)and (orientation: portrait) {
  .Products__PaginationSeparator {
    display: none;
  }
}

