@import "variables";

.Location {
  background-color: $color_dark;
  display: flex;
  height: 100vh;
  margin-bottom: 0;
}

.Location__Map {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

#map {
  height: 100%; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.Location__MapImage {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.Location__MapControls {
  border-radius: .3vw;
  bottom: 3.5vw;
  height: 6vw;
  left: 2.8vw;
  overflow: hidden;
  position: absolute;
  width: 3vw;
  z-index: 1;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .Location__MapControls {
    display: none;
  }
}

.Location__MapControl {
  background-color: $color_dark;
  cursor: pointer;
  height: 3vw;
  transition: background .6s ease;
}

.Location__MapControl--ZoomIn {
  border-bottom: .07vw solid hsla(46, 24%, 89%, .1);
}

.Location__MapControl:hover {
  background-color: hsla(45, 8%, 59%, .9);
}

.Location__MapControl--ZoomIn {
  position: relative;
}

.Location__MapControl--ZoomIn:after, .Location__MapControl--ZoomIn:before {
  background-color: $color_light;
  content: "";
  height: .07vw;
  left: calc(50% - .3125vw);
  position: absolute;
  top: calc(50% - .03472vw);
  transform-origin: center;
  width: .625vw;
}

.Location__MapControl--ZoomIn:after {
  transform: rotate(90deg);
}

.Location__MapControl--ZoomOut {
  position: relative;
}

.Location__MapControl--ZoomOut:after {
  background-color: $color_light;
  content: "";
  height: .07vw;
  left: calc(50% - .3125vw);
  position: absolute;
  top: calc(50% - .03472vw);
  width: .625vw;
}

.Location__Info {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 14vh;
  width: 50%;
}

.Location__InfoTitle {
  color: $color_light;
  transform: translate3d(0, 28vh, 0);
  transition: opacity 1s $transition-timing-function, transform 1s cubic-bezier(.86, 0, .12, 1);
}

.Location__InfoText {
  color: $color_light;
  transform: translate3d(0, 28vh, 0);
  transition: opacity 1s $transition-timing-function, transform 1s cubic-bezier(.86, 0, .12, 1);
}

.DealerLocator__Search {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  //padding-top: 16vh;
  padding-top: 4vw;
  //width: 50vw
}

.DealerLocator__SearchTitle {
  color: $color_light;
  transform: translate3d(0, 28vh, 0);
  transition: opacity 1s $transition-timing-function, transform 1s cubic-bezier(.86, 0, .12, 1);
  //height: 6vw;
  //align-self: flex-end;
}

.DealerLocator__Search.isSearching .DealerLocator__SearchTitle {
  opacity: 0;
  transform: none;
  //height: 6vw;
}

.DealerLocator__SearchInputContainer:after { //, .DealerLocator__SearchInputContainer:before {
  background-color: $color_light_opacity_20; //hsla(46, 24%, 89%, .2);
  content: "";
  height: .07vw;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 1s $transition-timing-function;
  width: 100%;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .DealerLocator__SearchInputContainer:after, .DealerLocator__SearchInputContainer:before {
    height: .3vw;
    opacity: 1;
  }
}

.DealerLocator__SearchInputContainer:before {
  top: 0;
}

.DealerLocator__SearchInputContainer:after {
  bottom: 0;
}

.DealerLocator__Search.isSearching .DealerLocator__SearchInputContainer {
  transform: translateZ(0);
}

.DealerLocator__Search.isSearching .DealerLocator__SearchInputContainer:after, .DealerLocator__Search.isSearching .DealerLocator__SearchInputContainer:before {
  opacity: 1;
}

.DealerLocator__SearchInputContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 30vh, 0);
  transition: transform 1s cubic-bezier(.86, 0, .12, 1);
  z-index: 1;
}

.DealerLocator__SearchInput {
  background-color: $color_dark;
  border: none;
  caret-color: $color_light;
  color: $color_light;
  text-align: center;
  transform: scale(1.2);
  transition: transform 1s cubic-bezier(.86, 0, .12, 1);
  width: 100%;

  &:focus-visible {
    outline: none;
  }
}

.DealerLocator__SearchInput:-ms-input-placeholder {
  color: hsla(46, 24%, 89%, .8);
  transition: color .6s $transition-timing-function;
}

.DealerLocator__SearchInput::placeholder {
  color: hsla(46, 24%, 89%, .8);
  transition: color .6s $transition-timing-function;
}

.DealerLocator__SearchInput:focus:-ms-input-placeholder {
  color: hsla(46, 24%, 89%, 0);
}

.DealerLocator__SearchInput:focus::placeholder {
  color: hsla(46, 24%, 89%, 0);
}

.DealerLocator__Search.isSearching .DealerLocator__SearchInput {
  transform: scale(.825)
}

.DealerLocator__Search.isSearching .DealerLocator__SearchResults {
  opacity: 1;
  pointer-events: all;
  transition: opacity 1s $transition-timing-function .8s;
}

.DealerLocator__SearchResults {
  //height: 68%;
  margin-top: 3vw;
  opacity: 0;
  overflow: auto;
  pointer-events: none;
  transition: opacity .8s $transition-timing-function;
  width: 80%;
  z-index: 0
}

.DealerLocator__DialogResults {
  //width: 32vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.DealerLocator__SearchResult {
  background-color: white;
  border-radius: .4vw;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 12vw;
  justify-content: center;
  margin-bottom: .7vw;
  //padding: 4vw .7vw;
  transition: background .6s $transition-timing-function;
  max-width: 36vw;

  color: $color_dark_dark_opacity_7;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  //text-align: center;

  a {
    color: $color_dark_dark_opacity_7;
    text-decoration: none;

    &:hover {
      color: $color_dark_dark;
      cursor: pointer;
    }
  }

}

.DealerLocator__SearchResult a {
  color: $color_dark_dark_opacity_7;
  text-decoration: none;
  width: auto;
}

.DealerLocator__SearchResult.light {
  background-color: $color_light;
}

.DealerLocator__SearchResultName {
  font-family: Ivar Display Condensed, serif;
  color: $color_dark_dark;
  text-align: center;
  width: auto;
}

.DealerLocator__SearchResultAddress {
  color: $color_dark_dark_opacity_7;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-lines: 2;
  //width: 50%;
}

/*.DealerLocator__SearchResultAddress a {
  color: $color_dark_dark_opacity_7;
  text-decoration: none;
}*/

.DealerLocator__SearchResultAddress:hover {
  color: $color_dark_dark;
  cursor: pointer;
}

/*.DealerLocator__SearchMail {
  word-wrap: break-word;
  color: $color_dark_dark_opacity_7;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%
}
.DealerLocator__SearchWeb {
  word-wrap: break-word;
  color: $color_dark_dark_opacity_7;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%
}
.DealerLocator__SearchPhone {
  word-wrap: break-word;
  color: $color_dark_dark_opacity_7;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%
}*/


/*.DealerLocator__SearchPhone>a {
  color: $color_dark_dark;
  text-decoration: none;
}

.DealerLocator__SearchWeb>a {
  color: $color_dark_dark;
  text-decoration: none;
}

.DealerLocator__SearchMail>a {
  color: $color_dark_dark;
  text-decoration: none;
}*/


/*.DealerLocator__SearchWeb:hover {
  color: $color_dark_dark;
  cursor: pointer;
}

.DealerLocator__SearchMail:hover {
  color: $color_dark_dark;
  cursor: pointer;
}

.DealerLocator__SearchPhone:hover {
  color: $color_dark_dark;
  cursor: pointer;
}*/

