@import "variables";

.SectionBoxImage__ScrollCta {
  //bottom: 2.8vw;
  top: 60vh;
  color: $color_light;
  position: fixed;
  right: 4vw;
}

.Homepage__ScrollCta {
  //bottom: 2.8vw;
  top: 60vh;
  color: $color_dark_dark;
  position: fixed;
  right: 4vw;
}

.SectionPage__ScrollCta {
  //bottom: 2.8vw;
  top: 60vh;
  color: $color_light;
  position: fixed;
  right: 4vw;
}

.ScrollCtaLabel {
  display: block;
  transform: rotate(-90deg) translate3d(100%,0,0);
}

.ScrollCtaStroke {
  background-color: hsla(45, 8%, 59%, .3);
  height: 11vw;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  width: 0.03vw;
}


.ScrollCtaStroke:after {
  animation: scrollCtaStroke 1.5s linear 2s infinite;
  background-color: $color_dark_dark;
  content: "";
  height: 100%;
  position: absolute;
  transform: scaleY(0);
  transform-origin: top;
  width: 100%;

}

@keyframes scrollCtaStroke {
  0% {
    transform: translateZ(0) scaleY(0)
  }

  20% {
    transform: translate3d(0,10%,0) scaleY(.8)
  }

  40% {
    transform: translate3d(0,20%,0) scaleY(.8)
  }

  60% {
    transform: translate3d(0,100%,0) scaleY(0)
  }

  to {
    transform: translate3d(0,100%,0) scaleY(0)
  }
}
/*
@media (max-width: 1024px) and (orientation: portrait) {
  .ScrollCta {
    bottom: auto;
    position: absolute;
    right: calc(50% - .13333vw);
    top: 65vh
  }
}
*/
@media (max-width: 1024px) and (orientation: portrait) {
  .ScrollCtaLabel {
    display: none
  }
}

/*@media (max-width: 1024px) and (orientation: portrait) {
  .ScrollCtaStroke {
    background-color: hsla(46, 24%, 89%, .15);
    height: 20vw;
    width: .27vw
  }
}*/


/*@media (max-width: 1024px)and (orientation: portrait) {
  .ScrollCtaStroke:after {
    background-color: $color_light
  }
}*/

