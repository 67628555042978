@import 'variables';

.SectionSlogan {
  background-color: $color_light;
  height: 100vh;

  @include separators;
}

.SectionSlogan__Container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  //padding-top: 8vw;
}

.SectionSlogan__BadgeHeader {
  box-sizing: border-box;
  //padding-bottom: 8vw;
  position: relative
}

.SectionSlogan__BadgePhrase {
  color: $color_dark_dark;
  //width: 70vw;
  //margin-left: 15vw;
  text-align: center;
  height: auto;
  padding: 2vw 15vw  6vw;
  position: relative;
  width: 100%
}

.SectionSlogan__Image {
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: 20vw;
  width: 66vw
}

.SectionSlogan__BadgeButton {
  background:none;
  border:solid thin $color_dark_dark_opacity_15;
  color: $color_evidence;
  margin-left: 47vw;
}

