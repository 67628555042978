@import "variables";

.Founder {

  background-color: $color_light;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    padding: 5vw;
  }

  &:before {
    border-top: .07vw solid $color_dark_dark_opacity_15;
    content: "";
    left: 2.5vw;
    position: absolute;
    right: 2.5vw;
    top: 0;
  }
;

  &:after {
    border-bottom: .07vw solid $color_dark_dark_opacity_15;
    bottom: 0;
    //content: "";
    left: 2.5vw;
    position: absolute;
    right: 2.5vw;
  }
;

  &Info {
    padding-bottom: 3vw;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 40%;
    }
  }

  &Title {
    color: $color_dark_dark;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: 6.7vw;
    }
  }

  &Text {
    color: $color_dark_dark;
    padding-top: 3vw;
    font-size: 16px !important;
    font-weight: 300;
    line-height: 1.2 !important;

    @include media-breakpoint-up(lg) {
      font-size: 1.2vw !important;
      line-height: 1.125 !important;
    }

    > p {
      margin-bottom: 1vw;
    }
  }

  &Image {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      margin-left: 15px;
    }

    @include media-breakpoint-up(lg) {
      width: 33%;
    }
  }
}
