@import 'variables';

.SectionFooter {
  background-color: $color_light;
  box-sizing: border-box;
  padding-bottom: 3vw;
  padding-top: 2vw;
  position: relative;

   &:after {
     background-color: $color_evidence;
     bottom: 0;
     content: "";
     height: 1.5vw;
     left: 0;
     position: absolute;
     width: 100%;
   }

  &__List {
    margin-bottom: 0;

    &Title {
      font-size: 1.5rem;
      margin-bottom: 2vw;
      padding-bottom: 1vw;
      position: relative;

      @include media-breakpoint-up(sm) {
        font-size: 2.3vw;
      }

      &:after {
        background-color: $color_dark_dark_opacity_15;
        bottom: 0;
        content: "";
        height: .07vw;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__Link {
    color: $color_dark_dark;

    @include media-breakpoint-up(sm) {
      font-size: 1vw;
    }

    & > a {
      color: inherit;
      text-decoration: none;
      transition: opacity .6s $transition-timing-function;

      &:hover {
        opacity: .5;
      }
    }
  }

  &__Info {
    margin-top: 2rem;
    color: $color_dark_dark;

    @include media-breakpoint-up(sm) {
      font-size: 1vw;
    }

    & > a {
      color: $color_dark_dark;
      text-decoration: none;

      &:hover {
        opacity: .5;
      }
    }
  }

  &__Legals {
    &Links {
      text-transform: uppercase
    }

    &Link {
      font-size: .875rem;

      @include media-breakpoint-up(sm) {
        font-size: .8vw;
      }

      & > a {
        transition: opacity .6s $transition-timing-function;
      }
    }

    &Company {
      margin-bottom: 0;
      font-size: .75rem;

      @include media-breakpoint-up(sm) {
        font-size: .7vw;
      }
    }
  }

  &__Logo {
    width: 40%;
    margin-bottom: 1vw;

    @include media-breakpoint-up(sm) {
      width: 6vw;
    }
  }
}



