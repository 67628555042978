@import "variables";

.PartnersRules {
  //border-top: .07vw solid $color_dark_dark_opacity_15;
  background-color: $color_dark_dark;
  height: 100vh;
  padding: 4vw 8vw 4vw;
  @include separators;
}

.PartnersRules__Container {

}

.PartnersRules__Header {

}

.PartnersRules__Phrase {
  color: $color_light;
  padding-bottom: 6vw;
  padding-top: 4vw;
}

.PartnersRules__Article {
  border-top: .07vw solid $color_light_opacity_15;
  padding: 2vw 0 1vw;
}

.PartnersRules__ArticleTitle {
  color: $color_light;
  text-align: left;
  font-family: Graphik, sans-serif;
  //font-size: 1vw;
  font-weight: 500;
  line-height: 2vw;
}

.PartnersRules__ArticleContent {
  color: $color_light;
  text-align: left;
  font-family: Graphik, sans-serif;
  font-size: 1vw;
  font-weight: 500;
  line-height: 2vw;
}